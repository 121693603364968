<template>
  <div class="category_warp">
    <Header></Header>

    <div class="category_main">
      <Nav></Nav>
      <div class="screen-outer">
      </div>
    </div>

    <Footer></Footer>

    <el-dialog
      class="dialog"
      :visible.sync="dialogVisible"
      width="804px"
      :before-close="handleClose">
      <div class="dialog_header" slot="title">
        Reset Password
      </div>  
      <div>
        
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/header/header.vue"
import Footer from "@/components/footer/footer.vue"
import Nav from "@/components/nav/nav.vue"
export default {
  components: {
    Header,
    Footer,
    Nav
  },
  data(){
    return{
      dialogVisible: false,
    }
  },
  methods: {
    handleClose(){
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.category_main{
  height: 1000px;
  background-color: #f7fafc;
  padding-bottom: 40px;
}
</style>