export default {
  more: '更多',
  search: '搜索',
  AllCategory: '所有分类',
  cart: '购物车',
  signin: '登录',
  register: '注册',
  signout: '退出登录',
  recommendeditems: '推荐',
  ourextraservices: '额外服务',
  text1: '让我们开始吧',
  text2: '你好，用户',
  text3: '待支付',
  text4: '处理中',
  text5: '运输中',
  text6: '退货',
  text7: '源头产品',
  text8: '定制需求',
  text9: '物流快捷',
  text10: '质量保证',
  text11: '各地厂家货物集中于我们仓库，客户下单以后我们提交订单给仓库，仓库操作人员负责按单拣货打包，然后直接从仓库发出去到顾客手里，全程无需在担忧运费的问题，在我们网站所购买的任何产品都是享有包邮服务',
  text12: '为保障采购方的利益，请先看清楚货物是否是您需要采购的货物，因为涉及国际运输，不涉及产品质量问题，我们是不承担运输费用的。如果有产品质量问题，请联系回客服，提供相对应的图片或视频给客服申请退款，具体货物如何处理客服回跟您这边沟通的',
  text13: '广州幸运购贸易有限公司是一家从事日用品批发,信息咨询服务,互联网销售等业务的公司，成立于2023年04月26日，公司坐落在广东省，详细地址为：广州市白云区镇湖大路2号2栋102铺;企业的经营范围为:日用品批发;信息咨询服务（不含许可类信息咨询服务）;互联网销售（除销售需要许可的商品）;贸易经纪;国内贸易代理;食品销售（仅销售预包装食品）;食品互联网销售（仅销售预包装食品）;办公设备销售;办公用品销售;日用百货销售;日用杂品销售;日用品销售;;进出口代理;技术进出口;货物进出口;',
  text14: '用户中心',
  text15: '用户信息',
  text16: '我的订单',
  text17: '用户服务',
  text18: '物流运输',
  text19: '退货售后',
  text20: '联系我们',
  text21: '关于我们',
  text22: '关于幸运购',
  text23: '来源于行业中心',
  text24: '定制您的产品',
  text25: '快速、可靠的海运或空运',
  text26: '产品监控和检查',
  text27: '服务协议和隐私政策',
  text28: '首页',
  text29: '全部',
  text30: '分类',
  text31: '价格区间',
  text32: '最低',
  text33: '最高',
  text34: '应用',
  text35: '件商品',
  text36: '排序',
  text37: '销量',
  text38: '免运费',
  text39: '查看详情',
  text40: '售出',
  text41: '价格',
  text42: '规格',
  text43: '数量',
  text44: '购买',
  text45: '添加到购物车',
  text46: '猜你喜欢',
  text47: '商品详情',
  text48: '登录',
  text49: '账号',
  text50: '密码',
  text51: '注册',
  text52: '忘记密码？',
  text53: '最少6个字符',
  text54: '保持唯一性',
  text55: '确认密码',
  text56: '邮箱',
  text57: '我同意',
  text58: '和',
  text59: '隐私政策',
  text60: '服务协议',
  text61: '返回',
  text62: '订单通知',
  text63: '平台通知',
  text64: '全部清除',
  text65: '暂无消息',
  text66: '暂无产品',
  text67: '返回商城',
  text68: '删除',
  text69: '我的购物车',
  text70: '地址',
  text71: '暂无商品',
  text72: '您可以返回商城',
  text73: '选择其它地址',
  text74: '暂无地址',
  text75: '点击',
  text76: '添加地址',
  text77: '小计',
  text78: '运费',
  text79: '优惠',
  text80: '总计',
  text81: '税款',
  text82: '获取支付账号',
  text83: '上传支付凭证',
  text84: '支付流程',
  text85: '线下支付',
  text86: '提交凭据',
  text87: '发货',
  text88: '订单支付',
  text89: '上传凭据',
  text90: '如果您已经线下付款，请上传您的付款凭证。',
  text91: '点击上传或者拖拽图片到此处上传',
  text92: '上传',
  text93: '选择地址',
  text94: '添加其它地址',
  text95: '取消',
  text96: '联系我们',
  text97: '上传成功',
  text98: '感谢您的支持，我们将尽快发货。',
  text99: '添加地址',
  text100: '姓名：',
  text101: '名：',
  text102: '手机号码：',
  text103: '地址1：',
  text104: '地址2：',
  text105: '地区或省份：',
  text106: '市：',
  text107: '邮寄编码：',
  text108: '设为默认',
  text109: '添加',
  text110: '地址列表',
  text111: '添加地址',
  text112: '昵称',
  text113: '手机号',
  text114: '查看全部',
  text115: '邮箱',
  text116: '已完成',
  text117: '全部',
  text118: '已关闭',
  text119: '商品',
  text120: '总价',
  text121: '状态',
  text122: '订单编号',
  text123: '暂无订单',
  text124: '关闭订单',
  text125: '请求退款',
  text126: '取消退款',
  text127: '订单详情',
  text128: '退款发起中',
  text129: '个人中心',
  text130: '我的账号',
  text131: '税款',
  text132: '我的订单',
  text133: '个人信息',
  text134: '保存',
  text135: '修改',
  text136: '线下支付',
  text137: '注意',
  text138: '请阅读以下账户相关信息，并保存账户以便线下支付。付款后，您需要在“我的订单”中上传付款凭证。',
  text139: '收款账户',
  text140: '复制',
  text141: '汇款通知',
  text142: 'SOP流程',
  text143: '汇款备注只写付款或一些常见备注，敏感词不能备注。',
  text144: '如果您需要使用ATM机汇款，请先与我们沟通/确认。',
  text145: '如果您忘记了密码，请联系我们，我们将帮助您重置密码。',
  text146: '客服服务',
  text147: '账单凭证',
  text148: '订单付款后，请上传凭证。',
  text149: '地址信息',
  text150: '编辑',
  text151: '数量',
  text152: '操作',
  text153: '日期',
  text154: '退货地址信息',
  text155: '退货运输方式',
  text156: '商家通过退货申请后，您需要按照此地址退货。',
  text157: '退货后，您需要在此处补充物流信息。',
  text158: '添加物流信息',
  text159: '如果您已经退货，请填写物流信息。',
  text160: '物流公司：',
  text161: '物流编号：',
  text162: '确认',
  text163: '你确定要关闭这个订单吗？',
  text164: '关闭订单',
  text165: '你确定收到货物吗？',
  text166: '确认收货',
  text167: '你确定取消申请退款吗？',
  text168: '取消退款申请',
  text169: '取消',
  text170: '您确定退出登录么？',
  text171: '退出登录',
  text172: '物流信息',
  text173: '支付方式',
  text174: '只有尚未发货的订单才允许更改发货地址。',
  text175: '重置',
  text176: '退款已受理',
  text177: '暂无',
  text178: '忘记密码',
  text179: '重新上传',
  text180: '退款编号',
  text181: '退款状态',
  text182: '添加物流',
  text183: '如果您已经退货，请填写物流信息。',
  text184: '已退回',
  text185: '订单倒计时',
  text186: '产品名称或者订单号',
  text187: '原因：',
  text188: '退款金额：',
  text189: '退款银行账号：',
  text190: '退款最大金额不超过',
  text191: '请输入您的退款原因',
  text192: '请输入您的退款金额',
  text193: '请输入您的退款银行账号',
  text194: '请确保收款账户正确无误，以便获得退款。',
  text195: '账号',
  text196: '删除消息',
  text197: '你确定要删除这条消息吗？',
  text198: '删除',
  text199: '你确定要删除所有的消息吗？',
  text200: '详细地址：',
  text201: '请输入详细地址，包括街道和楼号。',
  text202: '你确定要删除这条地址吗？',
  text203: '删除地址',
  text204: '编辑地址',
  text205: '编辑',
  text206: '不能超过退款的最大金额',
  text207: '退款完成',
  text208: '更多分类',
  text209: '复制',
  text210: '复制',
}