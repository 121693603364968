<template>
  <div id="app">
    <Sidebar></Sidebar>
    <router-view/>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/sidebar.vue"

import { shopWebsocket } from "./api/common.js"

export default {
  created(){


    let token = localStorage.getItem('token')
    if(token){
      let wsUrl = `wss://ap.luck-go.com/lyg-boot/api/shop/websocket/`+token;
			this.websocket = new WebSocket(wsUrl);
      this.websocket.onopen = function () {
        console.log('ws已连接')
      };
			this.websocket.onerror = function (err) {
        console.log('ws错误')
        console.log('ws 出现错误', err)
      };
      this.websocket.onclose = function () {
        console.log('ws已关闭')
      };
    }
    
  },
  components: {
    Sidebar
  },
  data(){
    return{
      websocket: null
    }
  },
  methods:{}

}
</script>

<style lang="scss">
#app{
  position: relative;
}
</style>
