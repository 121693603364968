import request from '@/utils/request'

export function userRegister(data) {
  return request({
    url: '/api/shop/user/register',
    method: 'post',
    data
  })
}

export function userLogin(data) {
  return request({
    url: '/api/shop/user/login',
    method: 'post',
    data
  })
}

export function userInfo() {
  return request({
    url: '/api/shop/user/info',
    method: 'get'
  })
}

export function userInfoUpdate(data) {
  return request({
    url: '/api/shop/user/info/update',
    method: 'post',
    data
  })
}

export function userAddressList(data) {
  return request({
    url: '/api/shop/user/address/list',
    method: 'get'
  })
}

export function userAddressUpdate(data) {
  return request({
    url: '/api/shop/user/address/update',
    method: 'post',
    data
  })
}

export function userAddressCreate(data) {
  return request({
    url: '/api/shop/user/address/create',
    method: 'post',
    data
  })
}

export function userAddressDelete(data) {
  return request({
    url: '/api/shop/user/address/delete',
    method: 'post',
    data
  })
}

export function userLogout() {
  return request({
    url: '/api/shop/user/logout',
    method: 'post'
  })
}