import request from '@/utils/request'

export function paymentConfig() {
  return request({
    url: '/api/shop/common/payment/config',
    method: 'get'
  })
}

export function paymentSop() {
  return request({
    url: '/api/shop/common/payment/sop',
    method: 'get'
  })
}

export function csConfig() {
  return request({
    url: '/api/shop/common/cs/config',
    method: 'get'
  })
}

export function shopWebsocket(id_) {
  return request({
    url: '/api/shop/websocket/'+id_,
    method: 'get'
  })
}

export function setPrice(val_) {
  // 价格强制保留两位小数
  var f = parseFloat(val_);
  if (isNaN(f)) return false;
  var f = Math.round(val_ * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf('.');
  if (rs < 0) {
      rs = s.length;
      s += '.';
  }
  while (s.length < (rs + 1) + 2) {
      s += '0';
  }
  return s
}
