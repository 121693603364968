import request from '@/utils/request'

export function messageList(data) {
  return request({
    url: '/api/shop/message/list',
    method: 'get',
    params: data
  })
}

export function messageRemind() {
  return request({
    url: '/api/shop/message/remind',
    method: 'get'
  })
}

export function messageDelete(data) {
  return request({
    url: '/api/shop/message/delete',
    method: 'post',
    data
  })
}

export function noticeList() {
  return request({
    url: '/api/shop/message/notice/list',
    method: 'get'
  })
}

export function messageRead(data) {
  return request({
    url: '/api/shop/message/read',
    method: 'post',
    data
  })
}