import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
  {
    path: '/',
    name: 'index',
    component: function () {
      return import('../views/index/index.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/login/login.vue')
    }
  },
  {
    path: '/register',
    name: 'register',
    component: function () {
      return import('../views/register/register.vue')
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: function () {
      return import('../views/detail/detail.vue')
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: function () {
      return import('../views/forgot/forgot.vue')
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: function () {
      return import('../views/reset/reset.vue')
    }
  },
  {
    path: '/category',
    name: 'category',
    component: function () {
      return import('../views/category/category.vue')
    }
  },
  {
    path: '/order',
    name: 'order',
    component: function () {
      return import('../views/order/order.vue')
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: function () {
      return import('../views/personal/personal.vue')
    }
  },
  {
    path: '/message',
    name: 'message',
    component: function () {
      return import('../views/message/message.vue')
    }
  },
  {
    path: '/orderDetail',
    name: 'message',
    component: function () {
      return import('../views/orderDetail/orderDetail.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
