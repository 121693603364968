<template>
  <div class="sidebar_warp" v-loading="loading">

    <div class="sidebar_main">
      <div class="ico ico-comment cspt" @click="gotoMessage();">
        <span class="message" v-if="unReadTotal!=0">{{unReadTotal}}</span>
      </div>
      <div class="ico ico-call cspt" @click="openContact()"></div>
    </div>

    <el-dialog
      class="dialog"
      :visible.sync="dialogVisible"
      width="804px">
      <div class="dialog_header" slot="title">
        {{isForgot?$t('text178'):$t('text146')}}
      </div>  
      <div>
        <p class="reset_text" v-if="isForgot">{{$t('text145')}}</p>
        <div class="copy_box">
          <p class="copy_title">WhatsApp: {{csConfigData.whatsApp}}</p>
          <span class="copy_btn cspt" v-clipboard:copy="csConfigData.whatsApp" v-clipboard:success="onCopy" v-clipboard:error="onError">{{$t('text140')}}</span>
        </div>
        <div class="copy_box">
          <p class="copy_title">E-mail: {{csConfigData.email}}</p>
          <span class="copy_btn cspt" v-clipboard:copy="csConfigData.email" v-clipboard:success="onCopy" v-clipboard:error="onError">{{$t('text140')}}</span>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { csConfig } from "@/api/common.js"
import { messageRemind } from "@/api/message.js"
export default {
  created(){
    this.init()
  },
  data(){
    return{
      dialogVisible: false,
      isForgot: false,
      csConfigData: {},
      loading: false,
      messageData: {},
      unReadTotal: 0
    }
  },
  watch: {
    // 监听窗口打开事件
    dialogVisible:{
      immediate:true,
      handler(){
        if(this.dialogVisible){
          this.getCsConfig()
        }
      }
    }
  },
  methods:{
    init(){
      let token = localStorage.getItem('token')
      if(token){
        messageRemind().then(res=>{
          this.messageData = res.result
          this.unReadTotal = this.messageData.unReadNum
        })
      }

      this.$EventBus.$on('openContact', res=>{
        this.openContact(res)
      })

      this.$EventBus.$on('setMessageNum', res=>{
        this.setMessageNum()
      })
    },
    onCopy(){
      this.$message({
        message: 'copy success',
        type: 'success'
      });
    },
    onError(){
      this.$message({
        message: 'copy error',
        type: 'error'
      });
    },
    gotoMessage(){
      let token = localStorage.getItem('token')
      if(token){
        this.$router.push('/message')
      }else{
        this.$router.push('/login')
      }
      
    },
    handleClose(){},
    openContact(val_){
      if(val_){
        this.isForgot = true
      }else{
        this.isForgot = false
      }
      this.dialogVisible = true
    },
    setMessageNum(){
      messageRemind().then(res=>{
        this.messageData = res.result
        this.unReadTotal = this.messageData.unReadNum
      })
    },
    getCsConfig(){
      this.loading = true
      csConfig().then(res=>{
        this.csConfigData = res.result
        this.loading = false
      })
    },
    copy(val_){
      document.execCommand(val_);
      this.$message({
        message: 'copy success',
        type: 'success'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  .dialog_header{
    text-align: center;
    font-size: 32px;
    color: #000;
    background: #f1f0f0;
    padding: 24px 0;
    font-weight: 400;
  }
  .reset_text{
    font-size: 20px;
    color: #8b96a5;
    padding: 0 30px 0 60px;
    position: relative;
    line-height: 30px;
    margin-bottom: 46px;
    &::before{
      content: "";
      position: absolute;
      top: 10px;
      left: 38px;
      display: inline-block;
      width: 9px;
      height: 20px;
      background: #ff5d21;
    }
  }
  .copy_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 88px 0 90px;
    margin-bottom: 36px;
    .copy_title{
      font-size: 20px;
      color: #8b96a5;
    }
    .copy_btn{
      display: inline-block;
      width: 156px;
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      border: 1px solid #f24822;
      background-color: #ffc8b3;
      color: #ff6830;
      font-weight: 400;
      text-align: center;
      border-radius: 6px;
    }
  }
  ::v-deep .el-dialog__header{
    padding: 0;
  }
  ::v-deep .el-dialog{
    margin: 0!important;
    border-radius: 6px;
    // overflow: hidden;
  }
  ::v-deep .el-dialog__headerbtn{
    font-size: 34px;
    top: 28px;
    right: 52px;
  }
  ::v-deep .el-dialog__body{
    padding: 58px 20px;
  }
}

.sidebar_main{
  position: fixed;
  top: 260px;
  right: 0;
  width: 74px;
  height: 146px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: -2px 3px 11px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  .ico{
    display: inline-block;
    width: 40px;
    height: 40px;
  }
  .ico-comment{
    position: relative;
    background: url("../../assets/image/comment.png") no-repeat center/100%;
    margin-bottom: 28px;
    .message{
      position: absolute;
      display: inline-block;
      width: 22px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      top: -7px;
      right: -7px;
      background-color: #EE263D;
      border-radius: 50%;
      font-size: 14px;
      color: #ffffff;
      opacity: 0.7;
    }
  }
  .ico-call{
    background: url("../../assets/image/call.png") no-repeat center/100%;
  }
}
</style>