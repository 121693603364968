<template>
  <div class="nav_warp">
    <div class="screen-outer">
      <div class="nav_main">
        <div class="all_menu">
          <span class="ico ico-menu"></span>
          All Category
        </div>
        <div class="menu_item">Hot offers</div>
        <div class="menu_item">Gift boxes</div>
        <div class="menu_item">projecs</div>
        <div class="menu_item">menu item</div>
        <div class="menu_item">help</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.nav_main{
  padding: 18px 0;
  display: flex;
  justify-content: left;
  align-items: center;
}
.ico{
  display: inline-block;
  width: 18px;
  height: 14px;
}
.ico-menu{
  margin-right: 12px;
  background: url("@/assets/image/menu.png") no-repeat center/100%;
}
.all_menu{
  width: 166px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e3e6;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  color: #363250;
  cursor: pointer;
  &:hover{
    color: #007580;
  }
}
.menu_item{
  color: #636270;
  font-size: 14px;
  font-weight: 700;
  margin-left: 32px;
  cursor: pointer;
  &:hover{
    color: #007580;
  }
}
</style>