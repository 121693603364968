export default {
  more: 'MORE',
  search: 'Search',
  AllCategory: "All Category",
  cart: 'Cart',
  signin: 'Sign In',
  register: 'Register',
  signout: 'Sign Out',
  recommendeditems: 'Recommended Items',
  ourextraservices: 'Our Extra Services',
  text1: "Let's Get Start",
  text2: "Hi,User",
  text3: 'Unpaid',
  text4: 'Processing',
  text5: 'Shipping',
  text6: 'Returns',
  text7: 'Source Product',
  text8: 'Customized Requirements',
  text9: 'Fast Logistics',
  text10: 'Quality Assurance',
  text11: "The goods from various manufacturers are concentrated in our warehouse. After the customer places an order, we submit the order to the warehouse. The warehouse operator is responsible for picking and packaging the goods according to the order, and then directly shipping them from the warehouse to the customer's hands. There is no need to worry about shipping costs throughout the entire process. Any product purchased on our website is eligible for package shipping services",
  text12: "To protect the interests of the purchaser, please first check whether the goods are the ones you need to purchase. As it involves international transportation and does not involve product quality issues, we do not bear transportation costs. If there are any product quality issues, please contact customer service and provide corresponding pictures or videos to apply for a refund. The customer service will communicate with you regarding the specific handling of the goods",
  text13: "Guangzhou Lucky Shopping Trading Co., Ltd. is a company engaged in wholesale of daily necessities, information consulting services, internet sales, and other businesses. Established on April 26, 2023, the company is located in Guangdong Province, with a detailed address at Shop 102, Building 2, Zhenhuda Road, Baiyun District, Guangzhou; The business scope of the enterprise is: wholesale of daily necessities; Information consulting services (excluding licensed information consulting services); Internet sales (excluding sales of goods that require licensing); Trade brokerage; Domestic trade agency; Food sales (only pre packaged food is sold); Food internet sales (only pre packaged food is sold); Office equipment sales; Sales of office supplies; Sales of daily necessities; Sales of daily miscellaneous goods; Sales of daily necessities;; Import and export agency; Technology import and export; Import and export of goods.",
  text14: 'For Users',
  text15: 'Profile',
  text16: 'My Orders',
  text17: 'Customer Service',
  text18: 'Shipping',
  text19: 'About Return',
  text20: 'Contact Us',
  text21: 'About',
  text22: 'About Luck-Go',
  text23: 'Source From Industry Hubs',
  text24: 'Customize Your Products',
  text25: 'Fast, Reliable Shipping By Ocean Or Air',
  text26: 'Product Monitoring And Inspection',
  text27: 'Privacy & Cookie Policy',
  text28: 'Home',
  text29: 'See All',
  text30: 'Category',
  text31: 'Price Range',
  text32: 'Min',
  text33: 'Max',
  text34: 'Apply',
  text35: 'Items',
  text36: 'Sort By',
  text37: 'Orders',
  text38: 'Free Shipping',
  text39: 'View Details',
  text40: 'Sold',
  text41: 'Price',
  text42: 'Spec',
  text43: 'QTY',
  text44: 'BUY',
  text45: 'ADD TO CART',
  text46: 'You May Like',
  text47: 'Description',
  text48: 'Sign In',
  text49: 'User Name',
  text50: 'Password',
  text51: 'Register',
  text52: 'Forgot Your Password?',
  text53: '6 Characters Minimum',
  text54: 'Keep Unique',
  text55: 'Confirm Password',
  text56: 'Email Address',
  text57: 'I Agree To The',
  text58: 'And',
  text59: 'Privacy & Cookie Policy',
  text60: 'Terms & Conditions',
  text61: 'Return',
  text62: 'Orders',
  text63: 'Platform',
  text64: 'Clean Up',
  text65: 'No News',
  text66: 'No Products Available',
  text67: 'Back To Shop',
  text68: 'Remove',
  text69: 'MY CART',
  text70: 'Ship To',
  text71: 'Nothing At All!',
  text72: 'You Can Back To Shop.',
  text73: 'Choose',
  text74: 'No Address!',
  text75: 'Click To',
  text76: 'Add Address',
  text77: 'Subtotal',
  text78: 'Shiping Fee',
  text79: 'Discount',
  text80: 'Total',
  text81: 'Tax',
  text82: 'Get Payment Account',
  text83: 'Upload Payment Voucher',
  text84: 'Order Steps',
  text85: 'Offline Payments',
  text86: 'Submit Credentials',
  text87: 'Ship',
  text88: 'Order Pay',
  text89: 'Upload Voucher',
  text90: 'lf you have paid offline, please upload your payment voucher.',
  text91: 'Click to upload or drag the picture here',
  text92: 'Upload',
  text93: 'Shipping To',
  text94: 'Add different shipping address',
  text95: 'Cancel',
  text96: 'Contact Us',
  text97: 'Successfully',
  text98: 'Thank you for your support,we will ship as soon as possible.',
  text99: 'Shipping Address',
  text100: 'Name *:',
  text101: 'Last Name :',
  text102: 'Phone Number *:',
  text103: 'Address Line 1 :',
  text104: 'Address Line 2 :',
  text105: 'State/Province :',
  text106: 'City',
  text107: 'Post/Zip Code *:',
  text108: 'Make Default',
  text109: 'Add',
  text110: 'Address Book',
  text111: 'Add New Address',
  text112: 'NickName',
  text113: 'Phone Number',
  text114: 'View All',
  text115: 'E-mail',
  text116: 'Completed',
  text117: 'All',
  text118: 'Closed',
  text119: 'Products',
  text120: 'Total',
  text121: 'State',
  text122: 'Order',
  text123: 'No Orders',
  text124: 'Cancel Order',
  text125: 'Request Refund',
  text126: 'Cancel Refund',
  text127: 'Order Details',
  text128: 'Refund Verifying',
  text129: 'Personal Center',
  text130: 'My Account',
  text131: 'Address Book',
  text132: 'My Orders',
  text133: 'My Profile',
  text134: 'Save',
  text135: 'Change',
  text136: 'Offline Pay',
  text137: 'NOTE',
  text138: 'Please read the following account-related information, and save the accountfor offline payment. After payment, you need to upload the payment voucher in "My Orders".',
  text139: 'Receivable Account',
  text140: 'COPY',
  text141: 'Remittance Notice',
  text142: 'SOP Process',
  text143: 'Remittance remarks only write PAYMENT or some common remarks, sensitivewords cannot be remarked.',
  text144: 'lf you need to use ATM to enter, please communicate/confirm with us first.',
  text145: "lf you've forgotten your password, please cantact us, We'll help you to reset your password",
  text146: 'Customer Service',
  text147: 'Billing Voucher',
  text148: 'After order payment, please upload the voucher.',
  text149: 'Shipping Information',
  text150: 'Edit',
  text151: 'Quantity',
  text152: 'Action',
  text153: 'Date',
  text154: 'Refund Shipping Information',
  text155: 'Refund Shipping Method',
  text156: 'After the merchant has passed the review, you need to return the goods according to this address.',
  text157: 'After returning the goods, you need to supplement the logistics information here.',
  text158: 'Add Logistics',
  text159: 'lf you have already returned the goods, please complete the logistics information.',
  text160: 'Logistics Company :',
  text161: 'Shipment Number :',
  text162: 'Confirm',
  text163: 'Are you sure to cancel the order?',
  text164: 'Cancel Order',
  text165: 'Are you sure to confirm receipt?',
  text166: 'Confirm Receipt',
  text167: 'Are you sure to cancel refund?',
  text168: 'Cancel Refund',
  text169: 'Cancel',
  text170: 'Are you sure to sign out?',
  text171: 'Sign Out',
  text172: 'Shipping Method',
  text173: 'Payment Method',
  text174: "Shipping address changes are only allowed on orders that haven't been shipped yet.",
  text175: 'Reset',
  text176: 'Refund accepted',
  text177: 'NONE',
  text178: 'Reset Password',
  text179: 'Re-upload',
  text180: 'Refund',
  text181: 'Refund State',
  text182: 'Add Logistics',
  text183: 'lf you have already returned the goods, please complete the logistics infomation.',
  text184: 'Returned',
  text185: 'Close The Order After',
  text186: 'Product Name/Order NO.',
  text187: 'Reason *:',
  text188: 'Refund Amount :',
  text189: 'Receipt Bank Account :',
  text190: 'The Maximum Amount Cannot Exceed',
  text191: 'Input Your Reason',
  text192: 'Fill in the refund amount',
  text193: 'Fill in the receipt bank accout',
  text194: 'Please ensure that the receiving account is correct in order toreceive a refund.',
  text195: 'Account',
  text196: 'Delete Message',
  text197: 'Are you sure to delete the message?',
  text198: 'Delete',
  text199: 'Are you sure to delete the all message?',
  text200: 'Detail Address *:',
  text201: 'Please enter detailed address, including street and building number.',
  text202: 'Are you sure to delete the address?',
  text203: 'Delete Address',
  text204: 'Edit Address',
  text205: 'Reset',
  text206: 'Cannot Exceed The Maximum Refund Amount',
  text207: 'Refund Completed',
  text208: 'More Category',
  text209: 'Tax',
  text210: 'Tax',
}