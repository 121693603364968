<template>
  <div class="footer_warp">
    <div class="footer_main">
      <div class="screen-outer">
        <div class="footer_top">
          <div class="top_left">
            <img class="logo" src="../../assets/image/logo.png" alt="">
            <div class="left_text">
              <div>
                <span>{{$t('text7')}}</span>
                <span style="margin: 0 10px;">|</span>
                <span>{{$t('text8')}}</span>
              </div>
              <div>
                <span>{{$t('text9')}}</span>
                <span style="margin: 0 10px;">|</span>
                <span>{{$t('text10')}}</span>
              </div>
            </div>
          </div>

          <div class="top_right">
            <div class="right_top">
              <div class="services_list">
                <p class="title">{{$t('text14')}}</p>
                <p class="link cspt" @click="gotoProfile()">{{$t('text15')}}</p>
                <p class="link cspt" @click="gotoOrder()">{{$t('text16')}}</p>
              </div>
              <div class="services_list">
                <p class="title">{{$t('text17')}}</p>
                <p class="link cspt" @click="dialogVisible1=true;">{{$t('text18')}}</p>
                <p class="link cspt" @click="dialogVisible2=true;">{{$t('text19')}}</p>
                <p class="link cspt" @click="openContact()">{{$t('text20')}}</p>
              </div>
              <div class="services_list">
                <p class="title">{{$t('text21')}}</p>
                <p class="link cspt" @click="dialogVisible3=true;">{{$t('text22')}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="df jc-sb ai-c">
          <div class="agreement_main">
            <span class="link">{{$t('text27')}}</span>
          </div>
        </div>

        <el-dialog
          class="dialog"
          :visible.sync="dialogVisible1"
          width="804px">
          <div class="dialog_header" slot="title">
            {{$t('text18')}}
          </div>  
          <div>
            <p class="text">{{$t('text11')}}</p>
          </div>
        </el-dialog>

        <el-dialog
          class="dialog"
          :visible.sync="dialogVisible2"
          width="804px">
          <div class="dialog_header" slot="title">
            {{$t('text19')}}
          </div>  
          <div>
            <p class="text">{{$t('text12')}}</p>
          </div>
        </el-dialog>

        <el-dialog
          class="dialog"
          :visible.sync="dialogVisible3"
          width="804px">
          <div class="dialog_header" slot="title">
            {{$t('text22')}}
          </div>  
          <div>
            <p class="text">{{$t('text13')}}</p>
          </div>
        </el-dialog>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false
    }
  },
  methods:{
    init(){

    },
    gotoProfile(){
      let token = localStorage.getItem('token');
      if(!token){
        this.$router.push('/login');
      }else{
        this.$router.push('/personal?page=profile');
      }
    },
    gotoOrder(){
      let token = localStorage.getItem('token');
      if(!token){
        this.$router.push('/login');
      }else{
        this.$router.push('/personal?page=order');
      }
    },
    openContact(){
      this.$EventBus.$emit('openContact')
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  .dialog_header{
    text-align: center;
    font-size: 32px;
    color: #000;
    background: #f1f0f0;
    padding: 24px 0;
    font-weight: 400;
  }

  .text{
    font-size: 18px;
    line-height: 33px;
    word-break: break-word;
  }
  
  ::v-deep .el-dialog__header{
    padding: 0;
  }
  ::v-deep .el-dialog{
    margin: 0!important;
    border-radius: 6px;
    // overflow: hidden;
  }
  ::v-deep .el-dialog__headerbtn{
    font-size: 34px;
    top: 28px;
    right: 52px;
  }
  ::v-deep .el-dialog__body{
    padding: 20px 36px;
  }
}

.footer_warp{
  background-color: #fff;
}
.footer_main{
  padding-top: 20px;
}
.agreement_main{
  text-align: right;
  color: #737373;
  margin: 12px 28px 12px 0;
  // margin-right: 28px;
  // margin-bottom: 28px;
  .link{
    margin-right: 28px;
  }
}
.footer_top{
  display: flex;
  justify-content: space-between;
  .top_left{
    .logo{
      width: 163px;
      // margin-bottom: 14px;
    }
    .left_text{
      width: 366px;
      color: #666666;
      line-height: 24px;
    }
  }
  .top_right{
    margin: 22px 52px 0 0;
    .right_top{
      display: flex;
      .services_list{
        margin-right: 82px;
        p{margin: 0;}
        .title{
          font-weight: 500;
          color: #1f1f1f;
          margin-bottom: 12px;
        }
        .link{
          color: #8c97a6;
          margin-bottom: 6px;
        }
      }
    }
    .right_bottom{
      .link{
        color: #737373;
      }
    }
  }
}
.footer_bottom{
  height: 68px;
  border-top: 1px solid #e1e4e9;
  background-color: #eff2f4;
  .bottom_main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 68px;
    .bottom_text{
      color: #767777;
    }
  }
}
</style>