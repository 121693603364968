<template>
  <div class="header_warp">
    <div class="screen-outer">
      <div class="header_main">
        <div class="header_left">
          <img class="logo cspt" src="../../assets/image/logo.png" alt="" @click="gotoHome();">
        </div>

        <div class="header_middle">
          <div class="middle_left">
            <span class="ico ico-18n"></span>

            <el-dropdown @command="changeLanguage">
              <span class="el-dropdown-link cspt">
                {{language}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="en">English</el-dropdown-item>
                <el-dropdown-item command="zh">中文</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="search_box">
            <el-input @change="search()" :placeholder="$t('search')" v-model="input" class="input-with-select" clearable @clear="search()">
              <!-- <el-select v-model="select" slot="append" :placeholder="$t('AllCategory')">
                <el-option :label="$t('AllCategory')" value="0"></el-option>
                <el-option :label="item.nameEn" :value="item.id" v-for="(item, index) in cartListData" :key="index">{{item.nameEn}}</el-option>
              </el-select> -->
              <el-button slot="append" @click="search()">{{$t('search')}}</el-button>
            </el-input>
          </div>
        </div>

        <div class="header_right">
          <div class="df jc-sb ai-c cspt" @click="gotoOrder()">
            <span class="ico ico-buycar"></span>
            <span class="car_text">{{$t('cart')}}</span>
          </div>
          <sup class="el-badge__content el-badge__content--warning car_tag" v-if="cartTotal!=0">{{cartTotal}}</sup>
          <span class="ico ico-order cspt" @click="gotoMessage()"></span>
          <span class="ico ico-profile cspt" @click="gotoPerson();"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfo } from "@/api/user.js"
import { cartList, categoryList } from "@/api/product.js"

export default {
  props:{
    inputValue: String
  },
  data(){
    return{
      input: null,
      select: "0",
      isLogin: false,
      cartTotal: 0,
      language: 'English',
      cartListData: [],
      langData: 'en'
    }
  },
  created(){
    this.init();
  },
  methods:{
    init(){
      if(this.inputValue&&this.inputValue!=''){
        var inputData = this.inputValue
      }
      this.input = inputData
      let token = localStorage.getItem('token');
      this.langData = localStorage.getItem('lang');
      if(this.langData=="en"){
        this.language = "English"
      }else{
        this.language = "中文"
      }
      if(token){
        this.isLogin = true
        userInfo().then(res=>{
          // console.log(res)
          if(res.success){
            cartList().then(res=>{
              // console.log(res)
              this.cartTotal = res.result.total
            })
          }
        })
      }else{
        this.isLogin = false
      }

      // categoryList().then(res=>{
      //   console.log(res)
      //   this.cartListData = res.result
      // })
    },
    search(){
      // console.log(this.select)
      this.$router.push('/category?q='+this.input)
    },
    changeLanguage(command) {
      // this.$message('click on item ' + command);
      this.$i18n.locale = command
      localStorage.setItem('lang', command)

      if(command=='en'){
        this.language = 'English'
      }else{
        this.language = '中文'
      }

      location.reload()
    },
    gotoPerson(){
      if(this.isLogin){
        this.$router.push('/personal');
      }else{
        this.$router.push('/login');
      }
    },
    gotoHome(){
      this.$router.push('/');
    },
    gotoOrder(){
      if(this.isLogin){
        this.$router.push('/order');
      }else{
        this.$router.push('/login');
      }
    },
    gotoMessage(){
      if(this.isLogin){
        this.$router.push('/personal?page=order')
      }else{
        this.$router.push('/login');
      }
    },
    changeNum(val1, val2){
      // if(val1==0){
      //   this.cartTotal = this.cartTotal - val2
      // }else{
      //   this.cartTotal = this.cartTotal + val2
      // }

      cartList().then(res=>{
        // console.log(res)
        this.cartTotal = res.result.total
      })
      
    },
  }
}
</script>

<style lang="scss" scoped>
.header_warp{
  background-color: #fff;
}
.header_main{
  height: 86px;
  display: flex;
  justify-content: space-between;
}
.header_left{
  .logo{
    height: 86px;
  }
}
.header_middle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .middle_left{
    display: flex;
    align-items: center;
    margin-right: 10px;
    .el-dropdown{
      font-weight: 700;
    }
  }
  
  .search_box{
    height: 40px;
    ::v-deep .el-select .el-input{
      width: 120px;
    }
    ::v-deep .el-input-group__append{
      padding: 0;
      overflow: hidden;
      border: 2px solid #3382f5;
      border-radius: 0 6px 6px 0;
    }
    ::v-deep .el-input-group>.el-input__inner{
      border: 2px solid #3382f5;
      border-right: 0;
      border-radius: 6px 0 0 6px;
      width: 350px;
    }
    ::v-deep .el-input-group__append .el-select{
      margin: -10px 0;
    }
    ::v-deep .el-input-group__append .el-button{
      margin: -10px 0;
      background-color: #0a75ff;
      border: none;
      color: #fff;
      border-radius: 0;
    }
  }
}
.header_right{
  display: flex;
  align-items: center;
  .car_text{
    font-size: 14px;
    margin-right: 14px;
    font-weight: 700;
  }
  .car_tag{
    margin-right: 38px;
    font-size: 4px;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
  }
}
.ico{
  display: inline-block;
  width: 22px;
  height: 22px;
}
.ico-18n{
  margin-right: 10px;
  background: url("@/assets/image/18n.png") no-repeat center/100%;
}
.ico-buycar{
  margin-right: 8px;
  background: url("@/assets/image/buycar.png") no-repeat center/100%;
}
.ico-heart{
  margin-right: 32px;
  background: url("@/assets/image/heart.png") no-repeat center/100%;
}
.ico-order{
  margin-right: 32px;
  background: url("@/assets/image/order.png") no-repeat center/100%;
}
.ico-profile{
  // margin-right: 6px;
  background: url("@/assets/image/profile.png") no-repeat center/100%;
}

</style>