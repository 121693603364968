import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入公共样式
import './assets/css/common.scss'

import Bus from "./utils/bus.js"

import i18n from './lang'

import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);

Vue.prototype.$EventBus = Bus

import { setPrice } from "@/api/common.js"
Vue.prototype.setPrice = setPrice

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
