import request from '@/utils/request'

export function shopProductList(data) {
  return request({
    url: '/api/shop/product/list',
    method: 'get',
    params: data
  })
}

export function productDetail(data) {
  return request({
    url: '/api/shop/product/detail',
    method: 'get',
    params: data
  })
}

export function categoryList(data) {
  return request({
    url: '/api/shop/product/category/list',
    method: 'get',
    params: data
  })
}

export function cartAdd(data) {
  return request({
    url: '/api/shop/product/cart/add',
    method: 'post',
    data
  })
}

export function orderList(data) {
  return request({
    url: '/api/shop/order/list',
    method: 'get',
    params: data
  })
}

export function cartList(data) {
  return request({
    url: '/api/shop/product/cart/list',
    method: 'get',
    params: data
  })
}

export function submitByCart(data) {
  return request({
    url: '/api/shop/order/submitByCart',
    method: 'post',
    data
  })
}

export function paymentProof(data) {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/api/shop/order/upload/paymentProof',
    method: 'post',
    data
  })
}

export function cartDelete(data) {
  return request({
    url: '/api/shop/product/cart/delete',
    method: 'post',
    data
  })
}

export function productRelation(data) {
  return request({
    url: '/api/shop/product/relation/recommend',
    method: 'get',
    params: data
  })
}


